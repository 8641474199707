.content {
    margin: 0.5rem;
}

@media(min-width: 900px) {
    .extend {
        transition: 0.3s ease;
        width: calc(100% - 16rem) !important;
    }
    .collapse {
        width: 100%;
        transition: 0.3s ease;
    }
}