.sidebar {
    float: left;
    left: 0;
    top: 0;
    background: var(--atrMainColor);
    overflow-x: hidden;
    min-height: 100vh;
}

@media(max-width: 900px) {
    .sidebar {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 200;
        height: 100%;
        overflow-y: scroll;
    }
}

.open {
    transition: 0.3s ease;
    width: 16rem;
}

.closed {
    transition: 0.3s ease;
    width: 0;
}

#appname {
    font-weight: bold;
}